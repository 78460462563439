import { Component, OnInit } from '@angular/core';
import { ContactUsModel } from 'src/app/shared/services/contact-us.model';
import { DaoService } from 'src/app/shared/services/dao.service';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.css']
})
export class ContactUsComponent implements OnInit {
  
  contactUsRecords: ContactUsModel[] = [];
  recordFilter: Set<string> = new Set();
  applyFilter: boolean = false;
  constructor(
    public daoService: DaoService
  ) {
    this.recordFilter.add('New');
    this.recordFilter.add('In Progress');
    this.recordFilter.add('Completed');
   }

  ngOnInit() {
    this.loadContactUsRecords();
  }

  /**
   * Loads contact us model to the array
   */
  loadContactUsRecords() {
    this.applyFilter=true;
    //Initialize for each filter
    this.contactUsRecords=[];
    this.daoService.fetchAllContactUsRecords().subscribe(doc => {
      doc.forEach(
        data => {
          var dataObj = data.data();
          this.recordFilter.forEach(filterValue=>{
            if (dataObj.status === filterValue) {
              var contactUsRecord: ContactUsModel = new ContactUsModel(dataObj.name, dataObj.email, dataObj.message, dataObj.status);
              this.contactUsRecords.push(contactUsRecord);
            }
          })
        }
      );
      this.applyFilter=false;
    });
  }

  /**
   * Merges the status of Contact us to DB
   * @param contactRecord
   */
  onChangeStatus(contactRecord: ContactUsModel) {
    this.daoService.mergeContactUsRecord(contactRecord);
    window.alert("Status has been changed");
  }

  /**
   * Method deletes the contact-us user from DB
   * @param contactRecord 
   */
  onDeleteContactUser(contactRecord: ContactUsModel){
    var sureDelete = window.confirm('Are you sure to delete the User?');
    if(sureDelete){
      this.daoService.deleteContactUser(contactRecord.email);
      this.loadContactUsRecords();
    }
  }

  /**
   * Loads the recordFilter property with filter values
   * @param event
   * @param statusFilter 
   */
  onCheckBoxClick(event:Event, statusFilter:string){
    if((<HTMLInputElement>event.target).checked){
      this.recordFilter.add(statusFilter);
    }else{
      this.recordFilter.delete(statusFilter);
    }
  }

}
