import { Component, OnInit } from '@angular/core';
import { DaoService } from 'src/app/shared/services/dao.service';
import { ContactUsModel } from 'src/app/shared/services/contact-us.model';

@Component({
  selector: 'app-landingpage',
  templateUrl: './landingpage.component.html',
  styleUrls: ['./landingpage.component.css']
})
export class LandingpageComponent implements OnInit {

  namemodel;
  emailmodel;
  messagemodel;

  constructor(public daoService: DaoService) { }

  ngOnInit() {
  }

  onSubmit() {
    var contactUs: ContactUsModel = new ContactUsModel(this.namemodel,this.emailmodel,this.messagemodel,"New");
    this.daoService.mergeContactUsRecord(contactUs);
    window.alert("Submitted your Details. Our representative will contact you soon!");
    console.log('submitted');
  }

}
