import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AuthService } from '../../shared/services/auth.service';
import { Observable } from 'rxjs';
import { UserService } from 'src/app/components/userprofile/user.service';

@Injectable({
  providedIn: 'root'
})

export class AdminGuard implements CanActivate {

  
  constructor(
    public authService: AuthService,
    public router: Router
  ){

   }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    var isAdmin: boolean;
      if(localStorage.getItem('userRole')==='ADMIN')
      {
        isAdmin = true;
      }else{
        window.alert('You are unauthorized!');
        this.router.navigate(['dashboard']);
        isAdmin = false;
      }
    return isAdmin;
  }

}