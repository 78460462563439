import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument, AngularFirestoreCollection } from '@angular/fire/firestore';
import { Post } from './post';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PostserviceService {
  postsCollection: AngularFirestoreCollection<Post>;

  constructor(private afs: AngularFirestore) {
    this.postsCollection = this.afs.collection('postpayment', ref => ref.orderBy('date', 'asc'));
  }

  getposts() {
    return this.postsCollection.snapshotChanges().pipe(map(changes => {
      changes.map(a => {
      const data = a.payload.doc.data() as Post;
      return {id : a.payload.doc.id, ...data};
      });
    }));
  }

  // itemsRef: AngularFireList<any>;
  // items: Observable<Note[]>;
  // constructor(db: AngularFireDatabase) {
  //   this.itemsRef = db.list('note-list');
  //   this.items = this.itemsRef.snapshotChanges().pipe(
  //      map(changes =>
  //        changes.map(c => ({ key: c.payload.key, ...c.payload.val() }))
  //     )
  //   );
  // }
}
