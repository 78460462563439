import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { ContactUsModel } from './contact-us.model';
import { BehaviorSubject } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DaoService {
  
  constructor(
    public afs: AngularFirestore   // Inject Firestore service
  ) { }

  /**
   * Merges the record into contact-us collection
   * @param contactUs 
   */
  mergeContactUsRecord(contactUs: ContactUsModel) {
    this.afs.doc('contact-us/' + contactUs.email).set(contactUs.toJavaObj(), { merge: true });
  }

  /**
   * Deletes the Contact-us record by email ID
   * @param email
   */
  deleteContactUser(email: string) {
    this.afs.doc('contact-us/' + email).delete();
  }

  /**
   * Method returns an observable. Iterate using forEach() 
   * to get the data from Observable
   * @param statusStr
   */
  fetchAllContactUsRecords() {
    return this.afs.collection('contact-us').get();
  }

  /**
   * Method returns an observable
   * Usage: this.fetchRecordsByStatus("<Status>").subscribe(val=><Functionality>);
   * @param statusStr
   */
  fetchContactUsRecordsByStatus(status: string) {
    var statusFilter$ = new BehaviorSubject(status);
    var recordObservable$ = statusFilter$.pipe(
      switchMap(
        status => this.afs.collection("contact-us",
          ref => ref.where('status', '==', status)
        )
          .valueChanges()
      )
    )
    return recordObservable$;
  }
}
