import { Component, NgZone, AfterContentChecked } from '@angular/core';
import { AuthService } from '../../shared/services/auth.service';
import { Router } from '@angular/router';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements AfterContentChecked {
  userRole: string;
  constructor(
    public authService: AuthService,
    public router: Router,
    public ngZone: NgZone
  ) {
  }
  ngAfterContentChecked() {
    this.userRole = localStorage.getItem('userRole');
  }
}
