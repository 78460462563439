export class ContactUsModel {
    contactUsObj: { name: string, email: string, message: string, status: string };
    constructor(
        public name: string,
        public email: string,
        public message: string,
        public status: string
    ) { }

    toJavaObj(){
        this.contactUsObj = {name:this.name,email:this.email,message:this.message,status:this.status};
        return this.contactUsObj;
    }
}
