import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Required components for which route services to be activated
import { SignInComponent } from '../../components/sign-in/sign-in.component';
import { SignUpComponent } from '../../components/sign-up/sign-up.component';
import { DashboardComponent } from '../../components/dashboard/dashboard.component';
import { ForgotPasswordComponent } from '../../components/forgot-password/forgot-password.component';
import { VerifyEmailComponent } from '../../components/verify-email/verify-email.component';

// Import canActivate guard services
import { AuthGuard } from '../../shared/guard/auth.guard';
import { SecureInnerPagesGuard } from '../../shared/guard/secure-inner-pages.guard';
import { PostpaymentComponent } from 'src/app/components/postpayment/postpayment.component';
import { UserprofileComponent } from 'src/app/components/userprofile/userprofile.component';
import { AdminComponent } from 'src/app/components/admin/admin.component';
import { AdminGuard } from '../guard/admin.guard';
import { LandingpageComponent } from 'src/app/components/landingpage/landingpage.component';
import { ContactUsComponent } from 'src/app/components/admin/contact-us/contact-us.component';
import { UsersControlComponent } from 'src/app/components/admin/users-control/users-control.component';
import { CheckoutComponent } from 'src/app/components/checkout/checkout.component';

// Include route guard in routes array
const routes: Routes = [
  
  { path: '', component: LandingpageComponent, pathMatch: 'full'},
  // { path: 'sign-in', redirectTo: '/sign-in', pathMatch: 'full'},
  { path: 'sign-in', component: SignInComponent, canActivate: [SecureInnerPagesGuard]},
  { path: 'register-user', component: SignUpComponent, canActivate: [SecureInnerPagesGuard]},
  { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard] , children: [{
    path: '' , component: UserprofileComponent, pathMatch: 'full'
  },
  {
    path: 'postpayment' , component:PostpaymentComponent
  },
  { 
    path: 'admin', component: AdminComponent, canActivate: [AdminGuard], children:[
    {
      path: 'contact-us', component: ContactUsComponent
    },
    {
      path: 'users-control', component: UsersControlComponent
    }
  ]
  },
  { 
    path: 'checkout', component: CheckoutComponent
  }
] },
  { path: 'forgot-password', component: ForgotPasswordComponent, canActivate: [SecureInnerPagesGuard] },
  { path: 'verify-email-address', component: VerifyEmailComponent, canActivate: [SecureInnerPagesGuard] }
  // {path: 'postpayment' , component:PostpaymentComponent , canActivate:[SecureInnerPagesGuard]}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})

export class AppRoutingModule { }