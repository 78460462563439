import { Component, OnInit } from '@angular/core';
import { PostserviceService } from './postservice.service';
import { Observable } from 'rxjs';
import { Post } from './post';

@Component({
  selector: 'app-postpayment',
  templateUrl: './postpayment.component.html',
  styleUrls: ['./postpayment.component.css']
})
export class PostpaymentComponent implements OnInit {
  posts;

  constructor(private post: PostserviceService) { }

  ngOnInit() {
  this.post.getposts().subscribe(result => {
    console.log(result);
  });
  }

}
