// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
  apiKey: 'AIzaSyDB-C6FGHEcgaylkvsRGbPpJIdTV6I2fE8',
  authDomain: 'ribatech-f9132.firebaseapp.com',
  databaseURL: 'https://ribatech-f9132.firebaseio.com',
  projectId: 'ribatech-f9132',
  storageBucket: 'ribatech-f9132.appspot.com',
  messagingSenderId: '252272441551',
  appId: '1:252272441551:web:93e624453121b99c'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
